module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":false,"disableMinification":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"新龍頭古厝民宿 | kinmen.life","short_name":"新龍頭古厝","start_url":"/","background_color":"#e8cb74","theme_color":"#e8cb74","display":"minimal-ui","icon":"src/img/頁籤頭.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
