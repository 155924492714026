// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-room-template-js": () => import("./../src/templates/RoomTemplate.js" /* webpackChunkName: "component---src-templates-room-template-js" */),
  "component---src-templates-hall-template-js": () => import("./../src/templates/HallTemplate.js" /* webpackChunkName: "component---src-templates-hall-template-js" */),
  "component---src-templates-about-us-template-js": () => import("./../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-contact-us-template-js": () => import("./../src/templates/ContactUsTemplate.js" /* webpackChunkName: "component---src-templates-contact-us-template-js" */),
  "component---src-templates-find-us-template-js": () => import("./../src/templates/FindUsTemplate.js" /* webpackChunkName: "component---src-templates-find-us-template-js" */),
  "component---src-templates-news-template-js": () => import("./../src/templates/NewsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

